<rModal class="api-token-modal">
  <div class="modal-header">
    <div *ngIf="mode === configureMode.EDIT" class="modal-title">
      {{
        action === configureAction.AUTHENTICATE
          ? ('Edit [integrationType] authentication information'
            | translate
            | translationParams: { integrationType: integration?.display_name })
          : ('Edit [integrationType] configuration information'
            | translate
            | translationParams: { integrationType: integration?.display_name })
      }}
    </div>
    <div *ngIf="mode === configureMode.ADD" class="modal-title">
      {{
        action === configureAction.AUTHENTICATE
          ? ('Authenticate with [integrationType]'
            | translate
            | translationParams: { integrationType: integration?.display_name })
          : ('Configure [integrationType] integration'
            | translate
            | translationParams: { integrationType: integration?.display_name })
      }}
    </div>
    <a (click)="close()" class="btn btn-clear modal-close">
      <icon class="si modal-close__icon" svgIcon="close"></icon>
    </a>
  </div>

  <form
    (ngSubmit)="authenticate()"
    *ngIf="action === configureAction.AUTHENTICATE"
    [formGroup]="authenticateForm"
    novalidate
  >
    <div class="modal-body">
      <ng-container *ngFor="let control of appConfig.authForm">
        <modal-field [control]="authenticateForm.get(control.name)" [label]="control.label | translate">
          <input
            [formControl]="authenticateForm.get(control.name)"
            class="form-modal__input"
            [type]="control.isPassword ? 'password' : 'text'"
          />
        </modal-field>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button (click)="close()" class="btn btn-secondary" type="button">{{ 'Cancel' | translate }}</button>
      <button [ladda]="isLoading" class="btn btn-primary">
        {{ 'Connect' | translate }}
      </button>
    </div>
  </form>
  <form (ngSubmit)="configure()" *ngIf="action === configureAction.CONFIGURE" [formGroup]="configureForm" novalidate>
    <content-state [isLoading]="isLoading" size="small">
      <div class="modal-body">
        <p *ngIf="mode === configureMode.ADD">
          {{
            'You have successfully made a connection with [integrationType]. The following form will configure the connection.'
              | translate
              | translationParams: { integrationType: integration?.display_name | decodeHtmlString }
          }}
        </p>
        <modal-field [control]="configureForm.get('mappingName')" [label]="'Mapping name' | translate">
          <input [formControl]="configureForm.get('mappingName')" class="form-modal__input" type="text" />
        </modal-field>

        <ng-container *ngFor="let control of appConfig.configureForm">
          <modal-field [control]="configureForm.get(control.name)" [label]="control.label | translate">
            <input [formControl]="configureForm.get(control.name)" class="form-modal__input" type="text" />
          </modal-field>
        </ng-container>

        <ng-container *ngIf="appConfig.contractFields?.length">
          <modal-field
            [control]="configureForm.get('sync_contract')"
            [label]="'Contract fields' | translate"
            [tooltipText]="
              'Contract fields that will be managed by [integrationType]'
                | translate
                | translationParams: { integrationType: integration.display_name | decodeHtmlString }
            "
          >
            <contract-sync
              class="w-full"
              [fields]="appConfig.contractFields"
              [formControl]="configureForm.get('sync_contract')"
            ></contract-sync>
          </modal-field>
        </ng-container>

        <ng-container *ngIf="appConfig.employeeFields?.length">
          <modal-field
            [control]="configureForm.get('sync_employee')"
            [label]="'Employee fields' | translate"
            [tooltipText]="
              'Employee fields that will be managed by [integrationType]'
                | translate
                | translationParams: { integrationType: integration.display_name | decodeHtmlString }
            "
          >
            <employee-sync
              class="w-full"
              [fields]="appConfig.employeeFields"
              [formControl]="configureForm.get('sync_employee')"
            ></employee-sync>
          </modal-field>
        </ng-container>

        <ng-container *ngIf="appConfig.autoAddEmployees">
          <div class="form-modal__element">
            <label class="form-modal__label"></label>
            <div class="form-modal__group">
              <label class="checkbox">
                <input [formControl]="configureForm.get('auto_add_employees')" class="checkbox-input" type="checkbox" />
                <span class="checkbox-indicator"></span>
                <span class="checkbox-description">{{ 'Automatically add new employees' | translate }}</span>
              </label>
              &nbsp;<icon
                class="si"
                svgIcon="info"
                [sbTooltip]="'Employees should be automatically added when no match can be found.' | translate"
              ></icon>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="appConfig.importVat">
          <div class="form-modal__element">
            <label class="form-modal__label"></label>
            <div class="form-modal__group">
              <label class="checkbox">
                <input type="checkbox" class="checkbox-input" [formControl]="configureForm.get('importVat')" />
                <span class="checkbox-indicator"></span>
                <span class="checkbox-description">{{ 'Import turnover including VAT' | translate }}</span>
              </label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="appConfig.notifyImport">
          <modal-field
            [control]="configureForm.get('notify_import')"
            [label]="'Notify import email address' | translate"
          >
            <input [formControl]="configureForm.get('notify_import')" class="form-modal__input" type="text" />
          </modal-field>
        </ng-container>
        <ng-container *ngIf="appConfig.turnOverImportEmail">
          <modal-field
            [control]="configureForm.get('turnover_import_email')"
            [label]="'Notify import email address' | translate"
          >
            <input [formControl]="configureForm.get('turnover_import_email')" class="form-modal__input" type="text" />
          </modal-field>
        </ng-container>
        <ng-container *ngIf="appConfig.notifyExport">
          <modal-field
            [control]="configureForm.get('notify_export')"
            [label]="'Notify export email address' | translate"
          >
            <input [formControl]="configureForm.get('notify_export')" class="form-modal__input" type="text" />
          </modal-field>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button (click)="close()" class="btn btn-secondary" type="button">{{ 'Cancel' | translate }}</button>
        <button
          (click)="editAuthenticationInformation()"
          *ngIf="mode === configureMode.EDIT && appConfig?.authForm?.length"
          class="btn btn-primary"
          type="button"
        >
          {{
            'Reconnect with [integrationType]'
              | translate
              | translationParams: { integrationType: integration?.display_name }
          }}
        </button>
        <button [ladda]="isLoading" class="btn btn-primary">
          {{ 'Save' | translate }}
        </button>
      </div>
    </content-state>
  </form>
</rModal>
